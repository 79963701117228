import React from "react";

import PNG_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@2x.png";
import PNG_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@1200_2x.png";
import PNG_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@992_2x.png";
import PNG_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@mob_2x.png";
import WEBP_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@2x.webp";
import WEBP_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@1200_2x.webp";
import WEBP_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@992_2x.webp";
import WEBP_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_1@mob_2x.webp";

import PNG_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@2x.png";
import PNG_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@1200_2x.png";
import PNG_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@992_2x.png";
import PNG_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@mob_2x.png";
import WEBP_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@2x.webp";
import WEBP_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@1200_2x.webp";
import WEBP_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@992_2x.webp";
import WEBP_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_2@mob_2x.webp";

import PNG_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@2x.png";
import PNG_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@1200_2x.png";
import PNG_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@992_2x.png";
import PNG_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@mob_2x.png";
import WEBP_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@2x.webp";
import WEBP_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@1200_2x.webp";
import WEBP_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@992_2x.webp";
import WEBP_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Find/find_3@mob_2x.webp";
import SVG_IC_GO from "../../../assets/images/vector//pages/amazon-sellers-bundle/ic_go_orange.svg";
import TrackedLink from "../../Analytics/TrackedLink";


const Link = ({ text, path }) => (
  <TrackedLink
    classes="PgASB-F-link"
    category="LANDING|amazon-sellers-bundle"
    action={text}
    path={path}
  >
    {text}
    <img src={SVG_IC_GO} alt="" />
  </TrackedLink>
);

const LINK_PROPS = {
  MONTHLY_TREND_REPORTS: {
    text: "Monthly Trend Reports",
    path: "/app/#/reports/reports?r=lp_bundle_t",
  },
  READY_TO_SELL_PRODUCTS: {
    text: "Ready-to-Sell Products",
    path: "/app/#/reports/insights?r=lp_bundle_t",

  },
  PRODUCT_DATABASE: {
    text: "Product Database",
    path: "/app/#/database?r=lp_bundle_t",
  },
  PRO_EXTENSION: {
    text: "PRO Extension",
    path: "https://chromewebstore.google.com/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_t",
  },
};

const FIND_CONTENT = [
  {
    screen: {
      PNG: PNG_One_2x,
      PNG_1200: PNG_One_1200_2x,
      PNG_992: PNG_One_992_2x,
      PNG_mob: PNG_One_mob_2x,
      WEBP: WEBP_One_2x,
      WEBP_1200: WEBP_One_1200_2x,
      WEBP_992: WEBP_One_992_2x,
      WEBP_mob: WEBP_One_mob_2x,
    },
    title: <>Check <Link {...LINK_PROPS.MONTHLY_TREND_REPORTS} /> and <Link {...LINK_PROPS.READY_TO_SELL_PRODUCTS} /></>,
    list: [
      "Pick your product to sell on Amazon from fantastic opportunities in the reports. Simply read the latest reports, check out the products and niches suggested, see their sales stats, and pick one. It's that easy.",
      "In case you want a very specific product and couldn’t find what you need in the reports, proceed with the Product Database",
    ],
  },
  {
    screen: {
      PNG: PNG_Two_2x,
      PNG_1200: PNG_Two_1200_2x,
      PNG_992: PNG_Two_992_2x,
      PNG_mob: PNG_Two_mob_2x,
      WEBP: WEBP_Two_2x,
      WEBP_1200: WEBP_Two_1200_2x,
      WEBP_992: WEBP_Two_992_2x,
      WEBP_mob: WEBP_Two_mob_2x,
    },
    title: <>Use <Link {...LINK_PROPS.PRODUCT_DATABASE} /> to:</>,
    list: [
      "Get a list of products that match your preferences in terms of sales margins, weight and 15 other filters out of 500 million products",
      "Easily filter out products with too much competition",
      "Never run out of ideas about potential new products worth researching",
    ],
    boldText: "The Product Database is crucial for private label, arbitrage, dropshipping, and wholesale product research.",
    text: <>Once you spot a product, track its performance with the <b>Product Tracker</b> to see real sales every day
      and observe product demand live before investing in it. It's a perfect Amazon FBA tool to closely monitor your
      competitors when you start selling.</>,
  },
  {
    screen: {
      PNG: PNG_Three_2x,
      PNG_1200: PNG_Three_1200_2x,
      PNG_992: PNG_Three_992_2x,
      PNG_mob: PNG_Three_mob_2x,
      WEBP: WEBP_Three_2x,
      WEBP_1200: WEBP_Three_1200_2x,
      WEBP_992: WEBP_Three_992_2x,
      WEBP_mob: WEBP_Three_mob_2x,
    },
    title: <>When you’ve found a product that you think is worth selling,
      use <Link {...LINK_PROPS.PRO_EXTENSION} /> to:</>,
    list: [
      "Quickly get an estimation of product potential and competition level. PRO Extension gives you a saturation score and product score from 1 to 10 for instant evaluation.",
      "Have a deeper look into products sales stats and sales history to make sure it is a safe investment and avoid getting stuck with a ton of product that doesn't sell",
      "Go to any Amazon product page and instantly see monthly revenue, competition strength, price history, and more relevant stats to make a final decision about whether you want to sell it",
    ],
    boldText: <>PRO Extension has more than 500,000 users and has helped to find <b>millions of profitable products</b> already.</>
  },
];

export default FIND_CONTENT;
